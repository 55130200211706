import { type PurchaseOrderResource } from '@amici/myamici-api-client'
import useSWR, { type KeyedMutator } from 'swr'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'

interface UsePurchaseOrderHook {
  data?: PurchaseOrderResource
  error?: Error
  isLoading: boolean
  mutate: KeyedMutator<PurchaseOrderResource>
}

function usePurchaseOrder (orderId?: string): UsePurchaseOrderHook {
  const { activeAccount } = useAccounts()
  const {
    ordersApi: { getOrder },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, error, isLoading, mutate } = useSWR<
    PurchaseOrderResource,
    Error
  >(
    orderId && accountId ? ['purchase-order', orderId, accountId] : null,
    async () => await fetcher(getOrder, { orderId: orderId ?? '', accountId })
  )

  return {
    data,
    error,
    isLoading,
    mutate
  }
}

export default usePurchaseOrder
