import ReactGA from 'react-ga4'

export interface UseOrderRequestTrackingEventResult {
  trackEvent: (itemId: string, action: string) => void
}

function useOrderRequestTrackingEvent (): UseOrderRequestTrackingEventResult {
  const trackEvent = (itemId: string, action: string): void => {
    ReactGA.event('action_item', {
      item_list_id: 'order_request',
      item_id: itemId,
      action
    })
  }

  return {
    trackEvent
  }
}

export default useOrderRequestTrackingEvent
