import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  type Supplier,
  type OrderRequestLineItem,
  ProductStatusEnum
} from '@amici/myamici-api-client'
import { type CheckedState } from '../../common/components/MaCheckbox'

export interface UseLineItemSelectionResult {
  selectedLineItemIds: string[]
  selectedSuppliers: Map<string, CheckedState>
  handleToggleSelectionBySupplier: (
    supplier: Supplier,
    checked: CheckedState
  ) => void
  handleItemSelectedChange: (
    lineItem: OrderRequestLineItem,
    checked: CheckedState
  ) => void
}

function useLineItemSelection (
  lineItems: OrderRequestLineItem[]
): UseLineItemSelectionResult {
  const [selectedLineItemIds, setSelectedLineItemIds] = useState<string[]>([])

  useEffect(() => {
    const lineItemExists = (id: string): boolean =>
      !!lineItems.find(lineItem => lineItem.line_item.id === id)

    if (!selectedLineItemIds.every(lineItemExists)) {
      setSelectedLineItemIds(selectedLineItemIds =>
        selectedLineItemIds.filter(lineItemExists)
      )
    }
  }, [lineItems, selectedLineItemIds])

  const handleItemSelectedChange = useCallback(
    (lineItem: OrderRequestLineItem, checked: CheckedState) => {
      setSelectedLineItemIds(lineItemIds => {
        const allLineItemIds = lineItems.map(lineItem => lineItem.line_item.id)
        return [
          ...lineItemIds
            .filter(id => allLineItemIds.includes(id))
            .filter(id => id !== lineItem.line_item.id),
          ...(checked ? [lineItem.line_item.id] : [])
        ]
      })
    },
    [lineItems]
  )

  const supplierIds = useMemo(
    () => [
      ...new Set(
        lineItems.map(lineItem => lineItem.line_item.product.supplier?.id)
      )
    ],
    [lineItems]
  )

  const selectedSuppliers = useMemo(
    () =>
      supplierIds.reduce((map, supplierId) => {
        const supplierLineItems = lineItems.filter(
          lineItem => lineItem.line_item.product.supplier?.id === supplierId
        )
        const isItemSelected = (lineItem: OrderRequestLineItem): boolean =>
          selectedLineItemIds.includes(lineItem.line_item.id)

        const checked: CheckedState = supplierLineItems.some(isItemSelected)
          ? supplierLineItems.every(isItemSelected)
            ? true
            : 'indeterminate'
          : false

        return map.set(supplierId, checked)
      }, new Map()),
    [lineItems, selectedLineItemIds, supplierIds]
  )

  const handleToggleSelectionBySupplier = useCallback(
    (supplier: Supplier, checked: CheckedState): void => {
      setSelectedLineItemIds(selectedLineItemIds => {
        const supplierLineItemIds = lineItems
          .filter(
            lineItem =>
              lineItem.line_item.product.supplier?.id === supplier.id &&
              lineItem.line_item.product.status === ProductStatusEnum.ACTIVE
          )
          .map(lineItem => lineItem.line_item.id)

        return [
          ...selectedLineItemIds.filter(
            id => !supplierLineItemIds.includes(id)
          ),
          ...(checked ? supplierLineItemIds : [])
        ]
      })
    },
    [lineItems]
  )

  return {
    selectedLineItemIds,
    selectedSuppliers,
    handleItemSelectedChange,
    handleToggleSelectionBySupplier
  }
}

export default useLineItemSelection
