import { type ReactElement, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useEnv } from '../../common/hooks/useEnv'
import usePurchaseOrder from '../hooks/usePurchaseOrder'
import useOrderItems from '../hooks/useOrderItems'
import useAccounts from '../../common/hooks/useAccounts'
import usePurchasingPermissions from '../hooks/usePurchasingPermissions'
import useValidateReorder from '../hooks/useValidateReorder'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'
import BuyAgainModal from '../components/BuyAgainModal'
import PurchaseOrderDeliveryInstructions from '../components/PurchaseOrderDeliveryInstructions'
import PurchaseOrderDetails from '../components/PurchaseOrderDetails'
import PurchaseOrderHeader from '../components/PurchaseOrderHeader'
import PurchaseOrderOrderItems from '../components/PurchaseOrderOrderItems'
import PurchaseOrderReference from '../components/PurchaseOrderReference'
import PurchaseOrderSummaryOfCharges from '../components/PurchaseOrderSummaryOfCharges'
import PurchaseOrderAdditionalDetailsPanel from '../components/PurchaseOrderAdditionalDetailsPanel'
import EditSpendCategoriesModal from '../../common/components/EditSpendCategoriesModal'
import {
  type OrderLineItemResource,
  type SpendCategory
} from '@amici/myamici-api-client'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import { type CheckedState } from '../../common/components/MaCheckbox'
import PurchaseOrderGroupEditSpendCategoriesModal from '../components/PurchaseOrderGroupEditSpendCategoriesModal'
import styles from '../assets/scss/PurchaseOrder.module.scss'
import useOrderHistory from '../hooks/useOrderHistory'
import ReceiveLineItemsModal from '../components/ReceiveLineItemsModal'

function PurchaseOrderActions ({
  reorderDisabled,
  onAddInvoice,
  onPrintOrder,
  onBuyAgain
}: Readonly<{
  reorderDisabled: boolean
  onAddInvoice: () => void
  onPrintOrder: () => void
  onBuyAgain: () => void
}>): ReactElement {
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const { isFinanceUser, hasFinanceModule } =
    usePurchasingPermissions(activeAccount)

  return (
    <MaActionMenu aria-label={t('purchase_order.actions.label')}>
      <MaActionMenuItem onClick={onPrintOrder}>
        {t('purchase_order.action.print_order')}
      </MaActionMenuItem>
      {isFinanceUser && hasFinanceModule && (
        <MaActionMenuItem onClick={onAddInvoice}>
          {t('purchase_order.action.add_invoice')}
        </MaActionMenuItem>
      )}
      <MaActionMenuItem onClick={onBuyAgain} disabled={reorderDisabled}>
        {t('purchase_order.action.buy_again')}
      </MaActionMenuItem>
    </MaActionMenu>
  )
}

function PurchaseOrder (): ReactElement {
  const { REACT_APP_MA3_BASE_URL } = useEnv()
  const { t } = useTranslation()
  const { orderId } = useParams()

  const {
    isLoading,
    error,
    data: order,
    mutate: refreshOrder
  } = usePurchaseOrder(orderId)
  const { successfulReorderItems, unsuccessfulReorderItems, reorderDisabled } =
    useValidateReorder(order?.id ?? '')
  const {
    data: lineItems,
    updateSpendCategories,
    groupUpdateSpendCategories,
    mutate: refreshLineItems
  } = useOrderItems(orderId ?? '')
  const { mutate: refreshHistory } = useOrderHistory(orderId)
  const [showBuyAgainModal, setShowBuyAgainModal] = useState(false)
  const [editLineItem, setEditLineItem] = useState<OrderLineItemResource>()
  const [showGroupEditModal, setShowGroupEditModal] = useState(false)
  const [selectedLineItemIds, setSelectedLineItemIds] = useState<string[]>([])
  const [lineItemsToReceive, setLineItemsToReceive] = useState<
    OrderLineItemResource[]
  >([])

  const { showToastMessage } = useToastNotification()

  useEffect(() => {
    setSelectedLineItemIds([])
  }, [orderId])

  const showOrder = !isLoading && !error && order
  const showError = !isLoading && error

  const ma3OrderUrl = `${REACT_APP_MA3_BASE_URL}/Order/OrderView.aspx?OrderId=${order?.id}`
  const addInvoiceUrl = `${REACT_APP_MA3_BASE_URL}/Invoice/InvoicePage1.aspx?OrderId=${order?.id}`
  const printOrderUrl = `${REACT_APP_MA3_BASE_URL}/Order/OrderPDF.aspx?OrderId=${order?.id}`

  const handleAddInvoice = (): void => {
    globalThis.open(addInvoiceUrl, '_blank')
  }

  const handlePrintOrder = (): void => {
    globalThis.open(printOrderUrl, '_blank')
  }

  const handleUpdateLineItemSpendCategories = async (
    spendCategories: SpendCategory[]
  ): Promise<void> => {
    setEditLineItem(undefined)
    /* istanbul ignore else */
    if (editLineItem) {
      try {
        await updateSpendCategories(editLineItem.id, spendCategories)
        void refreshHistory()
      } catch {
        showToastMessage('danger', t('purchase_order.order_item.edit.failure'))
      }
    }
  }

  const handleItemSelectedChange = (
    lineItem: OrderLineItemResource,
    checked: CheckedState
  ): void => {
    setSelectedLineItemIds(selectedLineItemIds => [
      ...selectedLineItemIds.filter(id => id !== lineItem.id),
      ...(checked ? [lineItem.id] : [])
    ])
  }

  const handleGroupEditSpendCategories = async (
    spendCategories: SpendCategory[]
  ): Promise<void> => {
    setShowGroupEditModal(false)
    const lineItemsToUpdate = lineItems?.content?.filter(lineItem =>
      selectedLineItemIds.includes(lineItem.id)
    )
    const updatedLineItems =
      lineItemsToUpdate?.map(lineItem => ({
        ...lineItem,
        spend_categories: [
          ...(lineItem.spend_categories ?? []).filter(
            existingSpendCategory =>
              !spendCategories.find(
                spendCategory =>
                  spendCategory.field_id === existingSpendCategory.field_id
              )
          ),
          ...spendCategories
        ].toSorted((a, b) => a.field_id.localeCompare(b.field_id))
      })) ?? []

    try {
      await groupUpdateSpendCategories(updatedLineItems)
      void refreshHistory()
    } catch {
      showToastMessage(
        'danger',
        t('purchase_order.group_edit_spend_categories.error')
      )
    }
  }

  const handleReceiveOutstanding = (): void => {
    setLineItemsToReceive(
      lineItems?.content?.filter(
        lineItem => lineItem.quantity !== lineItem.quantity_received
      ) ?? []
    )
  }

  const handleReceiveOutstandingSuccess = (): void => {
    showToastMessage('dark', t('purchase_order.receive_outstanding.success'))
    void refreshOrder()
    void refreshLineItems()
    void refreshHistory()
  }

  const handleReceiveOutstandingError = (): void => {
    showToastMessage('danger', t('purchase_order.receive_outstanding.error'))
  }

  return (
    <Container fluid="auto" className={classNames('ma-page')}>
      <PurchaseOrderHeader />

      {isLoading && !error && <LoadingSpinner />}

      {showError && (
        <p className={styles.error}>
          {t('purchase_order.not_found.sorry')}{' '}
          {t('purchase_order.not_found.message', { order_id: orderId })}
        </p>
      )}

      {showOrder && (
        <>
          <div className={styles['order-title-row']}>
            <h2 className={styles['order-title']}>
              <PurchaseOrderReference order={order} link={ma3OrderUrl} />{' '}
              {order?.confidential && (
                <span className={styles.confidential}>
                  {t('purchase_order.title.confidential')}
                </span>
              )}
            </h2>

            <PurchaseOrderActions
              reorderDisabled={reorderDisabled}
              onAddInvoice={handleAddInvoice}
              onPrintOrder={handlePrintOrder}
              onBuyAgain={() => {
                setShowBuyAgainModal(true)
              }}
            />
          </div>

          <div className={styles['panels-grid']}>
            <div className={styles['panels-row']}>
              <PurchaseOrderDetails
                className={styles['order-details']}
                order={order}
              />

              <PurchaseOrderSummaryOfCharges
                className={styles['summary-of-charges']}
                order={order}
              />
            </div>

            <PurchaseOrderDeliveryInstructions order={order} />

            <PurchaseOrderOrderItems
              lineItems={lineItems?.content}
              selectedLineItemIds={selectedLineItemIds}
              orderStatus={order.status}
              orderRequestReference={order?.order_request_reference}
              onEditLineItem={lineItem => {
                setEditLineItem(lineItem)
              }}
              onGroupEdit={() => {
                setShowGroupEditModal(true)
              }}
              onItemSelectedChange={handleItemSelectedChange}
              onReceiveOutstanding={handleReceiveOutstanding}
            />

            <PurchaseOrderAdditionalDetailsPanel
              order={order}
              lineItems={lineItems?.content}
            />
          </div>
        </>
      )}

      {order && !reorderDisabled && (
        <BuyAgainModal
          order={order}
          successfulOrderItems={successfulReorderItems}
          unsuccessfulOrderItems={unsuccessfulReorderItems}
          show={showBuyAgainModal}
          onClose={() => {
            setShowBuyAgainModal(false)
          }}
        />
      )}
      {order && (
        <EditSpendCategoriesModal
          title={t('purchase_order.order_item.edit.title')}
          show={!!editLineItem}
          selectedSpendCategories={editLineItem?.spend_categories ?? []}
          allowEmpty={false}
          onSave={handleUpdateLineItemSpendCategories}
          onCancel={() => {
            setEditLineItem(undefined)
          }}
          disabled={false}
        />
      )}
      <PurchaseOrderGroupEditSpendCategoriesModal
        disabled={false}
        show={showGroupEditModal}
        onSave={handleGroupEditSpendCategories}
        onCancel={() => {
          setShowGroupEditModal(false)
        }}
      />
      <ReceiveLineItemsModal
        variant="order"
        order={order}
        lineItems={lineItemsToReceive}
        onClose={() => {
          setLineItemsToReceive([])
        }}
        onSuccess={handleReceiveOutstandingSuccess}
        onError={handleReceiveOutstandingError}
      />
    </Container>
  )
}

export default PurchaseOrder
