import useSWR, { type KeyedMutator } from 'swr'
import {
  type OrderLineItemResource,
  type OrderLineItems,
  type OrdersApiUpdateLineItemSpendCategoriesRequest,
  type SpendCategory
} from '@amici/myamici-api-client'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import useSWRMutation from 'swr/mutation'

export interface UseOrderItemsHook {
  data?: OrderLineItems
  estimatedDeliveryDate?: Date
  error?: Error
  isLoading: boolean
  mutate: KeyedMutator<OrderLineItems>
  updateSpendCategories: (
    lineItemId: string,
    spendCategories: SpendCategory[]
  ) => Promise<SpendCategory[] | undefined>
  groupUpdateSpendCategories: (
    lineItems: OrderLineItemResource[]
  ) => Promise<OrderLineItems | undefined>
}

function useOrderItems (orderId: string): UseOrderItemsHook {
  const { activeAccount } = useAccounts()
  const {
    ordersApi: { getLineItems, updateLineItemSpendCategories },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, error, isLoading, mutate } = useSWR<OrderLineItems, Error>(
    orderId && accountId ? ['order-items', orderId, accountId] : null,
    async () => await fetcher(getLineItems, { orderId, accountId, size: 0 })
  )

  const { trigger: triggerUpdateSpendCategories } = useSWRMutation<
    SpendCategory[],
    Error,
    ['order-items', string, string] | null,
    OrdersApiUpdateLineItemSpendCategoriesRequest
  >(
    accountId ? ['order-items', orderId, accountId] : null,
    async (_, { arg }) => await fetcher(updateLineItemSpendCategories, arg),
    { revalidate: false }
  )

  const updateSpendCategories = async (
    lineItemId: string,
    spendCategories: SpendCategory[]
  ): Promise<SpendCategory[] | undefined> => {
    if (!accountId) {
      return
    }

    return await triggerUpdateSpendCategories(
      {
        orderId,
        lineItemId,
        spendCategoryReference: spendCategories,
        accountId
      },
      {
        optimisticData: currentData => {
          const lineItems = currentData as unknown as OrderLineItems
          return {
            ...lineItems,
            content: lineItems.content?.map(lineItem =>
              lineItem.id === lineItemId
                ? {
                    ...lineItem,
                    spend_categories: spendCategories
                  }
                : lineItem
            )
          } as unknown as SpendCategory[]
        }
      }
    )
  }

  const groupUpdateSpendCategoriesMutation = async (
    lineItems: OrderLineItemResource[]
  ): Promise<OrderLineItems> =>
    await Promise.all(
      lineItems.map(
        async lineItem =>
          await fetcher(updateLineItemSpendCategories, {
            accountId,
            orderId,
            lineItemId: lineItem.id,
            spendCategoryReference:
              lineItem.spend_categories?.map(spendCategory => ({
                id: spendCategory.id,
                field_id: spendCategory.field_id
              })) ?? []
          })
      )
    ).then(([lineItemSpendCategories]) => ({
      ...data,
      content: lineItems.map(lineItem => ({
        ...lineItem,
        spend_categories: lineItemSpendCategories
      }))
    }))

  const { trigger: triggerGroupUpdateSpendCategories } = useSWRMutation(
    accountId ? ['order-items', orderId, accountId] : null,
    async (_, { arg }: { arg: OrderLineItemResource[] }) =>
      await groupUpdateSpendCategoriesMutation(arg),
    { revalidate: false }
  )

  const groupUpdateSpendCategories = async (
    lineItems: OrderLineItemResource[]
  ): Promise<OrderLineItems | undefined> =>
    await triggerGroupUpdateSpendCategories(lineItems, {
      optimisticData: currentData => ({
        ...currentData,
        content: currentData?.content?.map(
          currentLineItem =>
            lineItems.find(lineItem => lineItem.id === currentLineItem.id) ||
            currentLineItem
        )
      })
    })

  const estimatedDeliveryDate = data?.content?.every(
    item => item.estimated_delivery_date
  )
    ? new Date(
        [...data.content].sort(
          (a, b) =>
            new Date(b.estimated_delivery_date ?? '').getTime() -
            new Date(a.estimated_delivery_date ?? '').getTime()
        )[0]?.estimated_delivery_date ?? 0
      )
    : undefined

  return {
    data,
    estimatedDeliveryDate,
    error,
    isLoading,
    mutate,
    updateSpendCategories,
    groupUpdateSpendCategories
  }
}

export default useOrderItems
