import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import useOrderRequest from '../hooks/useOrderRequest'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import { type OrderRequest } from '@amici/myamici-api-client'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import OrderRequestStatusUpdateModal from './OrderRequestStatusUpdateModal'
import useOrderRequestTrackingEvent from '../hooks/useOrderRequestTrackingEvent'

interface OrderRequestCancelModalProps {
  show: boolean
  orderRequest: OrderRequest
  onClose: () => void
}

function OrderRequestCancelModal ({
  show,
  orderRequest,
  onClose
}: Readonly<OrderRequestCancelModalProps>): ReactElement {
  const { t } = useTranslation()
  const { cancel, isCancelling } = useOrderRequest(orderRequest.id)
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequest.id)
  const { showToastMessage } = useToastNotification()

  const { trackEvent } = useOrderRequestTrackingEvent()

  const handleClose = (): void => {
    if (isCancelling) {
      return
    }
    onClose()
  }

  const handleCancel = (reason: string): void => {
    trackEvent(orderRequest.id, 'cancel')
    void cancel(reason)
      .catch(() => {
        showToastMessage('danger', t('order_request.cancel.error'))
      })
      .then(() => {
        void refreshHistory()
      })
      .finally(() => {
        handleClose()
      })
  }

  return (
    <OrderRequestStatusUpdateModal
      show={show}
      onClose={handleClose}
      onConfirm={handleCancel}
      disabled={isCancelling}
      confirmLabel={t('order_request.action.cancel')}
      title={t('order_request.cancel_form.title', {
        ref: orderRequest.order_ref
      })}
      reasonLabel={t('order_request.form.label.cancel_reason')}
      reasonRequired={true}
    />
  )
}

export default OrderRequestCancelModal
