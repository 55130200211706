import type {
  SpendCategory,
  SpendCategoryField
} from '@amici/myamici-api-client'
import {
  type PropsWithChildren,
  type ReactElement,
  type RefObject,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import useMinWidthObserver from '../../common/hooks/useMinWidthObserver'
import MaConfirm from '../../common/components/MaConfirm'
import SpendCategoryFieldSelect from '../../spend-categories/components/SpendCategoryFieldSelect'
import useSpendCategories from '../../spend-categories/hooks/useSpendCategories'

const FULL_VIEW_MIN_WIDTH_PX = 992

interface EditSpendCategoriesModalProps extends PropsWithChildren {
  show: boolean
  title?: string
  selectedSpendCategories: SpendCategory[]
  allowEmpty?: boolean
  onSave: (spendCategories: SpendCategory[]) => Promise<void>
  onCancel: () => void
  disabled: boolean
}

const spendCategoryComparator = (
  spendCategory1: SpendCategory,
  spendCategory2: SpendCategory
): number => spendCategory1.field_id.localeCompare(spendCategory2.field_id)

const isDirty = (
  initialSelection: SpendCategory[],
  updatedSelection: SpendCategory[]
): boolean =>
  JSON.stringify(initialSelection) !== JSON.stringify(updatedSelection)

const inactiveSpendCategorySelected = (
  selectedSpendCategories: SpendCategory[]
): boolean => selectedSpendCategories.some(value => value.status === 'inactive')
const allSpendCategoryFieldsSelected = (
  selectedSpendCategories: SpendCategory[],
  spendCategoryFields?: SpendCategoryField[]
): boolean | undefined =>
  spendCategoryFields?.every(spendCategoryField =>
    selectedSpendCategories.some(
      spendCategory => spendCategory.field_id === spendCategoryField.id
    )
  )

function EditSpendCategoriesModal ({
  show,
  title,
  selectedSpendCategories,
  allowEmpty = true,
  children,
  onSave,
  onCancel,
  disabled
}: Readonly<EditSpendCategoriesModalProps>): ReactElement {
  const { t } = useTranslation()
  const [spendCategories, setSpendCategories] = useState<SpendCategory[]>(
    selectedSpendCategories
  )
  const { data: spendCategoryFields } = useSpendCategories(true)
  const ref = useRef<any>(null)
  const compactView = !useMinWidthObserver(
    ref.current?.dialog as RefObject<Element>,
    FULL_VIEW_MIN_WIDTH_PX
  )

  useEffect(() => {
    setSpendCategories(selectedSpendCategories)
  }, [selectedSpendCategories, show])

  const onSpendCategoryFieldSelect = (
    newSpendCategory: SpendCategory
  ): void => {
    const updatedSpendCategories = [
      ...spendCategories.filter(
        value => value.field_id !== newSpendCategory.field_id
      )
    ]
    if (newSpendCategory.id !== -1) {
      updatedSpendCategories.push(newSpendCategory)
    }
    updatedSpendCategories.sort(spendCategoryComparator)
    setSpendCategories(updatedSpendCategories)
  }

  return (
    <MaConfirm
      ref={ref}
      size="lg"
      title={title}
      confirmLabel={t('common.button.labels.save')}
      closeLabel={t('common.button.labels.cancel')}
      show={show}
      disabled={
        disabled ||
        !isDirty(selectedSpendCategories, spendCategories) ||
        inactiveSpendCategorySelected(spendCategories) ||
        (!allowEmpty &&
          !allSpendCategoryFieldsSelected(spendCategories, spendCategoryFields))
      }
      onConfirm={() => {
        void onSave(spendCategories)
      }}
      onClose={onCancel}
    >
      {children}
      <SpendCategoryFieldSelect
        compactView={compactView}
        selectedSpendCategories={spendCategories}
        placeholder={
          allowEmpty
            ? t('spend_categories.label.no_change')
            : t('common.label.please_select')
        }
        allowEmpty={allowEmpty}
        emptyNodeName={t('spend_categories.label.no_change')}
        excludeInactive={true}
        onValueChange={onSpendCategoryFieldSelect}
        disabled={disabled}
      />
    </MaConfirm>
  )
}

export default EditSpendCategoriesModal
