import { Trans, useTranslation } from 'react-i18next'
import { type HistoryEvent } from '@amici/myamici-api-client'
import { type ReactElement } from 'react'
import { SpendCategoryHistoryEventType } from '../types/spend-category-history-event-type'

function UpdateSpendCategoryFieldStatusDetails ({
  entry
}: Readonly<{ entry: HistoryEvent }>): ReactElement {
  const context =
    entry.changes?.status.new_value === 'active' ? 'enabled' : 'disabled'
  return (
    <Trans
      i18nKey={'spend_categories.history.event.update_spend_category_field'}
      values={{ name: entry.object_reference }}
      context={context}
    />
  )
}

function UpdateSpendCategoryFieldNameDetails ({
  entry
}: Readonly<{ entry: HistoryEvent }>): ReactElement {
  return (
    <Trans
      i18nKey={
        'spend_categories.history.event.update_spend_category_field.name_change'
      }
      values={{
        old_name: entry.changes?.name.old_value,
        new_name: entry.changes?.name.new_value
      }}
    />
  )
}

function UpdateSpendCategoryStatusDetails ({
  entry
}: Readonly<{ entry: HistoryEvent }>): ReactElement {
  let context =
    entry.changes?.status.new_value === 'active' ? 'enabled' : 'disabled'
  if (!entry.custom_data?.parent_path) {
    context = `${context}_root`
  }
  return (
    <Trans
      i18nKey={'spend_categories.history.event.update_spend_category'}
      values={{
        name: entry.object_reference,
        field: entry.custom_data?.field_name,
        parent_path: entry.custom_data?.parent_path,
        code: entry.custom_data?.code
      }}
      context={context}
    />
  )
}

function UpdateSpendCategoryDetails ({
  entry
}: Readonly<{ entry: HistoryEvent }>): ReactElement | null {
  const { t } = useTranslation()
  const updateChanges: string[] = []
  if (entry.changes?.name) {
    updateChanges.push(
      t('spend_categories.history.event.update_spend_category.name', {
        ...entry.changes?.name
      })
    )
  }
  if (entry.changes?.code) {
    updateChanges.push(
      t('spend_categories.history.event.update_spend_category.code', {
        ...entry.changes?.code
      })
    )
  }
  if (entry.changes?.external_ref) {
    updateChanges.push(
      t('spend_categories.history.event.update_spend_category.external_ref', {
        ...entry.changes?.external_ref
      })
    )
  }
  if (entry.changes?.description) {
    updateChanges.push(
      t('spend_categories.history.event.update_spend_category.description', {
        ...entry.changes?.description
      })
    )
  }
  return (
    <Trans
      i18nKey={'spend_categories.history.event.update_spend_category.update'}
      values={{
        changes: updateChanges,
        field: entry.custom_data?.field_name,
        path: entry.custom_data?.path,
        code: entry.custom_data?.code
      }}
    />
  )
}

function UpdateSpendCategoryPathDetails ({
  entry
}: Readonly<{ entry: HistoryEvent }>): ReactElement {
  const fromRoot = !entry.changes?.parent_path.old_value
  const toRoot = !entry.changes?.parent_path.new_value
  const context = fromRoot ? 'from_root' : toRoot ? 'to_root' : undefined
  const i18nKey =
    entry.custom_data?.children_moved === 'true'
      ? 'spend_categories.history.event.update_spend_category.path_with_children'
      : 'spend_categories.history.event.update_spend_category.path'
  return (
    <Trans
      i18nKey={i18nKey}
      values={{
        ...entry.changes?.parent_path,
        name: entry.object_reference,
        field: entry.custom_data?.field_name,
        code: entry.custom_data?.code
      }}
      context={context}
    />
  )
}

function AddSpendCategoryDetails ({
  entry
}: Readonly<{ entry: HistoryEvent }>): ReactElement | null {
  const context = entry.changes?.path?.new_value ? 'with_path' : 'without_path'

  return (
    <Trans
      context={context}
      i18nKey={'spend_categories.history.event.add_spend_category'}
      values={{
        name: entry.changes?.name?.new_value,
        field: entry.custom_data?.field_name,
        path: entry.changes?.path.new_value,
        code: entry.custom_data?.code
      }}
    />
  )
}

function useSpendCategoryHistoryEntryDetails (
  entry: HistoryEvent
): ReactElement[] {
  const details: ReactElement[] = []
  switch (entry.event) {
    case SpendCategoryHistoryEventType.UPDATE_SPEND_CATEGORY_FIELD:
      if (entry.changes?.status) {
        details.push(<UpdateSpendCategoryFieldStatusDetails entry={entry} />)
      }
      if (entry.changes?.name) {
        details.push(<UpdateSpendCategoryFieldNameDetails entry={entry} />)
      }
      break
    case SpendCategoryHistoryEventType.UPDATE_SPEND_CATEGORY:
      if (entry.changes?.status) {
        details.push(<UpdateSpendCategoryStatusDetails entry={entry} />)
      }
      if (
        entry.changes?.name ||
        entry.changes?.code ||
        entry.changes?.external_ref ||
        entry.changes?.description
      ) {
        details.push(<UpdateSpendCategoryDetails entry={entry} />)
      }
      if (entry.changes?.parent_path) {
        details.push(<UpdateSpendCategoryPathDetails entry={entry} />)
      }
      break
    case SpendCategoryHistoryEventType.ADD_SPEND_CATEGORY:
      details.push(<AddSpendCategoryDetails entry={entry} />)
      break
  }
  return details
}

export default useSpendCategoryHistoryEntryDetails
