import { format, parseISO } from 'date-fns'

export enum DateFormat {
  DATE_LONG = 'd MMM yyyy'
}

export enum TimeFormat {
  TIME_SIMPLE = 'HH:mm'
}

export function formatDate (date: string, dateFormat: DateFormat): string {
  return format(parseISO(date), dateFormat)
}

export function formatTime (date: string, timeFormat: TimeFormat): string {
  return format(parseISO(date), timeFormat)
}
